import { useEffect, useRef } from 'react';
import { useDecision } from '@optimizely/react-sdk';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

export const DealsSortABControl = 'default_deals_sort_order_flag_off';
export const DealsSortABVariation1 = 'yec_default_alpha_sort_order_v1';
export const DealsSortABVariation2 = 'top_5_deals_sort_order_v2';

export const useDealTilePositioningAnalytics = (): void => {
  const analytics = useAnalytics();
  const [{
    enabled: decisionEnabled,
    variationKey: decisionVariationKey
  }] = useDecision('cb-deal_tile_positioning');
  const hasFiredEvent = useRef(false);

  useEffect(() => {
    if (!decisionEnabled || hasFiredEvent.current) {
      return;
    }
    switch (decisionVariationKey) {
      case DealsSortABControl:
        hasFiredEvent.current = true;
        analytics.push(() => ({
          beacon_id: '99.07.02',
          event: 'FFE-DealTilePositioning',
          event_category: 'Feature Flag Experiment',
          event_action: 'FlagOff_DealTilePositioning'
        }));
        break;
      case DealsSortABVariation1:
        hasFiredEvent.current = true;
        analytics.push(() => ({
          beacon_id: '99.08.02',
          event: 'FFE-DealTilePositioning',
          event_category: 'Feature Flag Experiment',
          event_action: 'YECDefaultAlphaSortV1_DealTilePositioning'
        }));
        break;
      case DealsSortABVariation2:
        hasFiredEvent.current = true;
        analytics.push(() => ({
          beacon_id: '99.09.02',
          event: 'FFE-DealTilePositioning',
          event_category: 'Feature Flag Experiment',
          event_action: 'Top5DealsSortV2_DealTilePositioning'
        }));
        break;
      default:
        break;
    }
  }, [analytics, decisionEnabled, decisionVariationKey]);
};
