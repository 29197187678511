import { useSelector } from 'react-redux';
import {
  useMemo, useRef
} from 'react';
import { useDecision } from '@optimizely/react-sdk';
import { ApolloError } from '@apollo/client';
import { Categories } from '@pizza-hut-us-development/client-core';
import { MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';
import {
  useTileReorderExperiment,
  useTileSuppressionExperiment
} from '@/experiments';
import useCCGetDealsMenu from '@/clientCore/temporaryTransformationalHooks/useCCGetDealsMenu';
import useTrackedDecision from '@/dataAnalytics/hooks/useTrackedDecision';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { UPDATED_LINEUP_TILE } from '@/deals/constants';
import { isLocalizedMenuPageSection, useCCGetProducts } from '@/clientCore/temporaryTransformationalHooks/useCCGetProducts';
import { useGetProductsToHideQuery } from '@/api/reduxToolkit/hideProducts';
import useDealTilePositioning from './hooks/useDealTilePositioning';

interface DealMenuData {
  deals: MenuRecipe[];
  loading: boolean;
}

const useDealMenu = (): DealMenuData => {
  const { data: hiddenProducts } = useGetProductsToHideQuery();

  const {
    data,
    isQueryLoading: loading,
    isError: ccError
  } = useCCGetDealsMenu({});

  const error = ccError
    ? new ApolloError({ errorMessage: 'ccError' })
    : undefined;

  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const [vluToDesiredPosition] = useDecision('fr-web-1505-vlu-deal-to-desired-position', { autoUpdate: true });
  const menuQueryLoading = useRef(true);

  // Lineup data queries
  const { data: ccLineupData } = useCCGetProducts({
    categoryId: Categories.LINEUP
  });

  const lineupData = isLocalizedMenuPageSection(ccLineupData) ? ccLineupData : undefined;

  const isLineupValid = lineupData && (lineupData.productsForMainCategory.length > 0 || (lineupData.subCategories?.length ?? 0) > 0);

  const dealsData = useTileSuppressionExperiment(
    data?.deals,
    !!(loading || error)
  );

  const filteredDeals = useMemo(() => (hiddenProducts && hiddenProducts.deals ? dealsData.filter((deal) => !hiddenProducts?.deals?.some((dealToHide) => deal.id.includes(dealToHide))) : dealsData),
    [dealsData, hiddenProducts]);

  const [dealsSortDecisionABTesting] = useTrackedDecision(
    'exp-abt-37-ha-stores-deals-sort',
    { optimizelyOptions: { autoUpdate: true } },
    {
      overrideAttributes: {
        storeNumber: storeDetails?.storeNumber
      }
    }
  );

  const [dealSortDecision] = useDecision(
    'ops-dtg47-deal_tile_positioning',
    { autoUpdate: true }
  );

  const dealsDataSorted = filteredDeals.sort((a, b) => a.priority - b.priority);

  const updateDealTilePriorities = () => {
    const tilePosition = vluToDesiredPosition?.variables?.tile_position as number;

    let newPriority: number;
    if (tilePosition > dealsDataSorted.length) {
      newPriority = dealsDataSorted[dealsDataSorted.length - 1]?.priority + 1;
    } else {
      newPriority = dealsDataSorted[tilePosition]?.priority;

      // increase priorities after our new placement priority +1
      dealsDataSorted.forEach((deal, index) => {
        if (deal.priority >= newPriority) {
          dealsDataSorted[index].priority += 1;
        }
      });
    }

    const updatedLineupTile = {
      ...UPDATED_LINEUP_TILE,
      priority: newPriority
    };

    dealsDataSorted.push(updatedLineupTile);
  };

  if (isLineupValid) {
    if (vluToDesiredPosition.enabled) {
      updateDealTilePriorities();
    } else {
      dealsDataSorted.push(UPDATED_LINEUP_TILE);
    }
    menuQueryLoading.current = false;
  } else {
    menuQueryLoading.current = false;
  }

  let dealsDataReordered = useTileReorderExperiment(
    dealsDataSorted,
    dealsSortDecisionABTesting.enabled ? dealsSortDecisionABTesting : dealSortDecision
  );

  const [mhbToTopDecision] = useDecision('dtg-405-mhb-deal-on-top-deal-list');
  dealsDataReordered = useMemo(() => {
    if (mhbToTopDecision.enabled) {
      return dealsDataReordered.reduce((deals, deal) => {
        let dealPriority = deal.priority;
        if (deal.id.includes('MYHUTBOX')) {
          dealPriority = 0;
        } else {
          dealPriority += 1;
        }
        return [...deals, { ...deal, priority: dealPriority }];
      }, [] as MenuRecipe[]);
    }
    return dealsDataReordered;
  }, [mhbToTopDecision.enabled, dealsDataReordered]);

  const dealTilesPrioritized = useDealTilePositioning({
    deals: dealsDataReordered
  });

  if (loading || error || menuQueryLoading.current) {
    return { deals: [], loading: Boolean(loading || menuQueryLoading.current) };
  }

  return { deals: dealTilesPrioritized, loading: Boolean(loading || menuQueryLoading.current) };
};

export default useDealMenu;
