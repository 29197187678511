import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useDecision } from '@optimizely/react-sdk';

import { useApplyPromoCodeMutation } from '@pizza-hut-us-development/client-core';

import { openLocalizationRail, openModal } from '@/localization/actions';
import { CART_EMPTY_WARNING_MODAL, SIGN_IN_WARNING_MODAL } from '@/header/profile/constants';
import { dealNotAvailableModalDetails } from '@/common/Modal/modalDetails';
import { openRail } from '@/rail/slices/Rail.slice';
import { RailType } from '@/rail/slices/Rail.slice.types';

import Routes from '@/router/routes';
import telemetry from '@/telemetry';

import { errorSelectors } from '@/graphql/errors/selectors';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { userDomainSelectors } from '@/header/userDomainSelectors';
import { cartSelectors } from '@/cart/cartSelectors';

import {
  selectDealToDisplay,
  setDealToDisplay
} from '@/builders/deals/slice/dealToDisplay.slice';
import { createItemAnalyticsForNationalDeals, onNationalDealMenuView } from '@/builders/deals/analytics/analytics';
import { useRedirectErrorHandling } from '@/hooks/useErrorHandling';
import { useApplicationInitialized } from '@/hooks/useApplicationInitialized';
import {
  useClearDealOnRouteChange,
  useClearDealOnStoreChange,
  useHistoryOnBrowserBack
} from '@/builders/deals/hooks/dealsPage';

import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import {
  POS_PARSED_SERIALIZED_CODE_LENGTH, POS_SERIALIZED_CODE_LENGTH, QO_SERIALIZED_CODE_LENGTH, YUM_SERIALIZED_CODE_LENGTH
} from '@/clientCore/cart/constants';
import { isRedemptionWarning, isResolvableWarning } from '@/clientCore/cart/components/CartRail/components/CartContent/components/YumAddedCoupons/helpers';
import { openCartRail } from '@/clientCore/redux/rail/CartRailSlice';

import dealsData from '@/common/pageSetup/nationalData/dealsData';

import { RootState } from '@/rootStateTypes';

import { DealsProps } from '../../../pages/deals';

type SerialCheckVariables = {
  deal_whitelist: {
    ids: string[];
  };
};

export const useDealsPage = ({
  dealGlobalId, ssrPrivateCode, ssrError, hasValidPrivateCode, id
}: DealsProps) => {
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const [isNewSerialCheckDecision] = useDecision('fr-web-3695-update_serial_logic');
  const { enabled: isNewSerialCheckEnabled } = isNewSerialCheckDecision;
  const newSerialWhitelistIds = (isNewSerialCheckDecision.variables as SerialCheckVariables)?.deal_whitelist?.ids;
  const [{ enabled: yumDirectLinkEnabled }] = useDecision('fr-web-3799-yum_direct_promo_linking');
  const [{ enabled: fixDeepLinkingLatencyToDealBuilderEnabled }] = useDecision('bug-dtg-1181-fix-latency-deeplinking-deal-builder-page', { autoUpdate: true });
  const dealError = useSelector(errorSelectors.dealGqlError);
  const pizzaError = useSelector(errorSelectors.pizzaGqlError);
  const userIsLocalized = useSelector(localizationSelectors.isLocalized);
  const dispatch = useDispatch();
  const dealId = useSelector(selectDealToDisplay)?.id;
  const router = useRouter();
  const analytics = useAnalytics();
  const isAppInitialized = useApplicationInitialized();
  const isDealBuilder = !!(dealGlobalId || dealId);
  const nationalDeals = dealsData();
  const isGuest = useSelector(userDomainSelectors.isGuest);
  const cartQuantity = useSelector(cartSelectors.quantity);
  const cart = useSelector(orderSelectors.cart);
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const [{ enabled: promoLinkFixEnabled }] = useDecision('fr-web-3997-promo_link_fix');
  const [applyYumPromoCode] = useApplyPromoCodeMutation();

  const signInRailOpened = useRef(false);

  const showSignInModal = () => {
    const openSignInRail = () => {
      signInRailOpened.current = true;
      dispatch(openRail(RailType.SIGN_IN));
    };

    const modalDetails = cartQuantity ? CART_EMPTY_WARNING_MODAL : SIGN_IN_WARNING_MODAL;
    const title = modalDetails.TITLE;
    const body = modalDetails.BODY;
    const primaryCtaText = modalDetails.CTAS.PRIMARY;
    const secondaryCtaText = modalDetails.CTAS.SECONDARY;
    const primaryCallback = !cartQuantity ? openSignInRail : undefined;
    const secondaryCallback = cartQuantity ? openSignInRail : undefined;
    dispatch(openModal({
      title,
      body,
      cta: {
        text: primaryCtaText,
        callback: primaryCallback
      },
      altCta: {
        text: secondaryCtaText,
        callback: secondaryCallback
      }
    }));
  };

  const oldIsSerializedCode = ((dealGlobalId?.length === QO_SERIALIZED_CODE_LENGTH)
        || (dealGlobalId?.length === POS_SERIALIZED_CODE_LENGTH && dealGlobalId?.startsWith('PH' || 'ph')));

  const newIsSerializedCode = !!(dealGlobalId?.length === QO_SERIALIZED_CODE_LENGTH
        || dealGlobalId?.length === YUM_SERIALIZED_CODE_LENGTH
        || (dealGlobalId?.includes('-')
            && dealGlobalId?.toLowerCase().startsWith('ph')
            && dealGlobalId?.split('-').pop()?.length === POS_PARSED_SERIALIZED_CODE_LENGTH))
        && !newSerialWhitelistIds?.includes(dealGlobalId);

  const isSerializedCode = isNewSerialCheckEnabled ? newIsSerializedCode : oldIsSerializedCode;

  useEffect(() => {
    if (dealId) {
      telemetry.addCustomEvent('deal-page-shown', {
        'public-code': dealGlobalId ?? null,
        'private-code': dealId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealId]);

  // Feature flagged useEffect
  const attemptedToAddPromo = useRef(false);
  useEffect(() => {
    if (!yumDirectLinkEnabled) return;
    if (typeof hasValidPrivateCode === 'boolean' && storeDetails) {
      if ((isYumEcomm && dealGlobalId)) {
        // Try to add as promo to cart, if not, set as deal for deal builder
        if (!cart || attemptedToAddPromo.current || (promoLinkFixEnabled && !cart.storeNumber?.length)) return;
        attemptedToAddPromo.current = true;
        applyYumPromoCode(dealGlobalId).then((res) => {
          if ('data' in res) {
            // If redemption code isn't valid, attempt to treat as deal
            const isSuccessfulWarning = !!res.data.warnings?.find((warning) => {
              if ('code' in warning && warning.code === dealGlobalId) {
                if (isRedemptionWarning(warning)) return isResolvableWarning(warning);
              }
              return false;
            });
            if (isSuccessfulWarning) {
              dispatch(openModal({
                title: 'Coupon Applied!',
                body: 'Your coupon has been added to the cart.',
                cta: {
                  text: 'Ok'
                },
                onClose: () => dispatch(openCartRail())
              }));
              return;
            }
          }
          dispatch(setDealToDisplay({ dealId: dealGlobalId, publicCode: dealGlobalId }));
        });
      } else if (hasValidPrivateCode && ssrPrivateCode) {
        dispatch(setDealToDisplay({ dealId: ssrPrivateCode, publicCode: dealGlobalId }));
      } else if (!hasValidPrivateCode && !ssrError) {
        router.push(Routes.DEALS);
        dispatch(openModal(dealNotAvailableModalDetails));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, ssrPrivateCode, isGuest, cartQuantity]);

  // Old useEffect
  useEffect(() => {
    if (yumDirectLinkEnabled) return;
    if (typeof hasValidPrivateCode === 'boolean' && storeDetails) {
      if (isYumEcomm && isGuest && isSerializedCode) {
        showSignInModal();
      } else if ((isYumEcomm && dealGlobalId)) {
        dispatch(setDealToDisplay({ dealId: dealGlobalId, publicCode: dealGlobalId }));
      } else if (hasValidPrivateCode && ssrPrivateCode) {
        dispatch(setDealToDisplay({ dealId: ssrPrivateCode, publicCode: dealGlobalId }));
      } else if (!hasValidPrivateCode && !ssrError) {
        router.push(Routes.DEALS);
        dispatch(openModal(dealNotAvailableModalDetails));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssrPrivateCode, isGuest, cartQuantity]);

  useEffect(() => {
    if (signInRailOpened.current && !isGuest && !isYumEcomm && ssrPrivateCode && dealGlobalId) {
      dispatch(setDealToDisplay({ dealId: ssrPrivateCode, publicCode: dealGlobalId }));
    }
  }, [isGuest, isYumEcomm, ssrPrivateCode, dealGlobalId, dispatch]);

  useClearDealOnStoreChange();
  useClearDealOnRouteChange();
  useHistoryOnBrowserBack();

  useEffect(() => {
    if (dealGlobalId && !userIsLocalized) {
      telemetry.addCustomEvent('national-deals-redirect-from-direct-access', {
        'public-code': dealGlobalId
      });
      router.replace(Routes.DEALS, undefined, { shallow: true });
      if (id) {
        dispatch(openLocalizationRail({ routeTo: `${Routes.DEALS}?id=${id}` }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealGlobalId, userIsLocalized]);

  useEffect(() => {
    if (!userIsLocalized && isAppInitialized) {
      const items = createItemAnalyticsForNationalDeals(nationalDeals);
      analytics.push(() => onNationalDealMenuView(items));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsLocalized, isAppInitialized]);

  useRedirectErrorHandling(ssrError!, (isDealBuilder) ? Routes.DEALS : Routes.MENU.MENU);

  const isError = dealError || pizzaError;
  const isDealBuilderPage = (fixDeepLinkingLatencyToDealBuilderEnabled ? isDealBuilder : dealId) && userIsLocalized;

  return {
    isError,
    isDealBuilderPage,
    dealId,
    userIsLocalized,
    nationalDeals
  };
};
