import React from 'react';
import { GetServerSideProps } from 'next';

import initializePage from '@/common/pageSetup';
import CenteredContainer from '@/common/CenteredContainer';
import logger from '@/common/logger';

import Header from '@/header';
import Footer from '@/footer';

import Menu from '@/menu/Menu';
import LocalizedMenu from '@/menu/deals/LocalizedMenu';
import DealBuilder from '@/builders/deals/DealBuilder';

import dealService from '@/services/dealService';
import { ErrorPage } from '@/ErrorPage';

import { WHITELISTED_DEALS } from '@/deals/constants';

import { useDealsPage } from '@/deals/hooks/useDealsPage';

export interface DealsProps {
  dealGlobalId?: string;
  ssrPrivateCode?: string | null;
  ssrError?: boolean;
  hasValidPrivateCode?: boolean;
  id: string | null;
}

const Deals = ({
  dealGlobalId,
  ssrPrivateCode,
  ssrError,
  hasValidPrivateCode,
  id
}: DealsProps): JSX.Element => {
  const {
    isError,
    isDealBuilderPage,
    dealId,
    userIsLocalized,
    nationalDeals
  } = useDealsPage({
    dealGlobalId, ssrPrivateCode, ssrError, hasValidPrivateCode, id
  });

  if (isError) {
    return <ErrorPage />;
  }

  // deal builder flow
  if (isDealBuilderPage) {
    return (
      <>
        <Header />
        <DealBuilder privateCode={dealId || ''} publicCode={dealGlobalId || ''} />
        <Footer />
      </>
    );
  }

  // list of localized or national deals flow
  return (
    <>
      <Header />
      <CenteredContainer>
        {userIsLocalized
          ? <LocalizedMenu />
          : (
            <Menu
              itemsTestId="national"
              title="Deals"
              items={nationalDeals}
              caloriesDisclaimer
              isNational
              isDeals
            />
          )}
      </CenteredContainer>
      <Footer />
    </>
  );
};
export default Deals;

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const { props } = await (initializePage(ctx, 'deals') as any);
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const discountCode = (ctx.req as any)?.query?.dealId || '';

  const dealGlobalId = (ctx.req as any)?.query?.id || '';
  const storeId = props.storeNumber;
  const { 'localization-token': localizationToken, 'x-server-env-override': xServerEnv } = ctx.req.cookies;

  if (discountCode) {
    return {
      props: {
        ...props,
        dealGlobalId,
        ssrPrivateCode: discountCode,
        hasValidPrivateCode: true
      }
    };
  }

  if (dealGlobalId && storeId && localizationToken) {
    try {
      const { privateCode } = await dealService.getPrivateCode(
        storeId,
        dealGlobalId,
        localizationToken,
        xServerEnv ?? ''
      );

      const isWhitelisted = !!privateCode && WHITELISTED_DEALS.includes(privateCode);
      const isValidPrivateCode = !!privateCode && (privateCode !== dealGlobalId || isWhitelisted);

      if (privateCode === dealGlobalId && !isWhitelisted) {
        logger.error(
          new Error('User prohibited from visiting deal because public code === private code'),
          { 'public code': dealGlobalId, 'private code': privateCode }
        );
      }

      return {
        props: {
          ...props,
          dealGlobalId,
          ssrPrivateCode: privateCode ?? null,
          hasValidPrivateCode: isValidPrivateCode ?? null
        }
      };
    } catch (error) {
      logger.withoutTelemetry.error('Failed to get deal ID: ', error);
      return {
        props: {
          ...props,
          dealGlobalId,
          ssrError: true
        }
      };
    }
  }
  return {
    props: {
      ...props,
      dealGlobalId
    }
  };
};
